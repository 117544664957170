import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBtnClipboardCopy, LazyDashboardModal, LazyDropFile, LazyUUID, LazyDocQualification, LazyJsonEditor, LazyOcrTab, LazyPeakDocument, LazyRefDocEditor, LazyDashboardNavbar, LazyDashboardPage, LazyDashboardPanel, LazyIconProfile, LazyLayoutSidebar, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["BtnClipboardCopy", LazyBtnClipboardCopy],
["DashboardModal", LazyDashboardModal],
["DropFile", LazyDropFile],
["UUID", LazyUUID],
["DocQualification", LazyDocQualification],
["JsonEditor", LazyJsonEditor],
["OcrTab", LazyOcrTab],
["PeakDocument", LazyPeakDocument],
["RefDocEditor", LazyRefDocEditor],
["DashboardNavbar", LazyDashboardNavbar],
["DashboardPage", LazyDashboardPage],
["DashboardPanel", LazyDashboardPanel],
["IconProfile", LazyIconProfile],
["LayoutSidebar", LazyLayoutSidebar],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
