import { default as annotatezknvsL0gq8Meta } from "/Users/gjeusel/src/anthracit/app/app/pages/annotate.vue?macro=true";
import { default as index0vB4v3l4jDMeta } from "/Users/gjeusel/src/anthracit/app/app/pages/index.vue?macro=true";
import { default as _91uuid_93e1P3HxZtEmMeta } from "/Users/gjeusel/src/anthracit/app/app/pages/references/[uuid].vue?macro=true";
import { default as indexs09MwcyvDTMeta } from "/Users/gjeusel/src/anthracit/app/app/pages/references/index.vue?macro=true";
import { default as referencesEdJzfmnkkwMeta } from "/Users/gjeusel/src/anthracit/app/app/pages/references.vue?macro=true";
export default [
  {
    name: "annotate",
    path: "/annotate",
    component: () => import("/Users/gjeusel/src/anthracit/app/app/pages/annotate.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/gjeusel/src/anthracit/app/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: referencesEdJzfmnkkwMeta?.name,
    path: "/references",
    component: () => import("/Users/gjeusel/src/anthracit/app/app/pages/references.vue").then(m => m.default || m),
    children: [
  {
    name: "references-uuid",
    path: ":uuid()",
    component: () => import("/Users/gjeusel/src/anthracit/app/app/pages/references/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "references",
    path: "",
    component: () => import("/Users/gjeusel/src/anthracit/app/app/pages/references/index.vue").then(m => m.default || m)
  }
]
  }
]