<script lang="ts" setup>
import { useBreakpoints, breakpointsTailwind } from "@vueuse/core"
import type { Group as SearchGroup } from "#ui/types"

const store = useUIStore()
store.$subscribe((_, state) => {
  localStorage.setItem(uiStoreStorageKey, JSON.stringify(state))
})

const icons = useIcons()
const colorMode = useColorMode()

const breakpoints = useBreakpoints(breakpointsTailwind)
const smallerThanSm = breakpoints.smaller("sm")

const color = computed(() => (colorMode.value === "dark" ? "#111827" : "white"))

useHead({
  meta: [
    { charset: "utf-8" },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    { key: "theme-color", name: "theme-color", content: color },
  ],
  link: [{ rel: "icon", href: "/favicon.ico" }],
  htmlAttrs: {
    lang: "en",
  },
})

useSeoMeta({ title: "Anthracit" })

const links = [
  {
    id: "home",
    label: "Home",
    icon: icons.home,
    to: "/",
    tooltip: {
      text: "Home",
      shortcuts: ["G", "H"],
    },
  },
  {
    id: "annotate",
    label: "Annotate",
    icon: icons.annotate,
    to: "/annotate",
    tooltip: {
      text: "Annotate",
      shortcuts: ["G", "A"],
    },
  },
  {
    id: "references",
    label: "References",
    icon: icons.references,
    to: "/references",
    tooltip: {
      text: "References",
      shortcuts: ["G", "R"],
    },
  },
]

const router = useRouter()
defineShortcuts({
  "g-h": () => router.push("/"),
  "g-a": () => router.push("/annotate"),
  "g-r": () => router.push("/references"),
  meta_k: {
    usingInput: true,
    handler: () => {
      store.showCommandModal = !store.showCommandModal
    },
  },
})

const common_actions = {
  key: "links",
  label: "Go to",
  commands: links.map((link) => ({ ...link, shortcuts: link.tooltip?.shortcuts })),
} satisfies SearchGroup

const page_actions = ref<SearchGroup[]>([])
provide("pageActions", page_actions)

const groups = computed<SearchGroup[]>(() => [common_actions, ...page_actions.value])

function handleCmdSelect(option: any) {
  if (option.click) {
    option.click()
  } else if (option.to) {
    router.push(option.to)
  } else if (option.href) {
    window.open(option.href, "_blank")
  }
  store.showCommandModal = false
}
</script>

<template>
  <div>
    <NuxtLoadingIndicator />

    <div class="fixed inset-0 flex overflow-hidden">
      <LayoutSidebar :links :collapsed="store.sidebarCollapsed" />
      <NuxtPage />
    </div>

    <ClientOnly>
      <UModal
        v-model="store.showCommandModal"
        :overlay="!smallerThanSm"
        :transition="!smallerThanSm"
      >
        <UCommandPalette
          ref="commandPaletteRef"
          :groups
          :autoselect="false"
          @update:model-value="handleCmdSelect"
        />
      </UModal>
    </ClientOnly>

    <UNotifications />
    <UModals />
  </div>
</template>
