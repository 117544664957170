export default defineAppConfig({
  ui: {
    primary: "orange",
    icons: {
      // routes
      home: "i-heroicons-home",
      annotate: "i-heroicons-book-open",
      references: "i-mdi-database-outline",
      //
      error: "i-heroicons-exclamation-circle-solid",
      success: "i-heroicons-check-16-solid",
      //
      copy: "i-fa6-regular-copy",
      close: "i-heroicons-x-mark-16-solid",
      search: "i-heroicons-magnifying-glass-16-solid",
      pdf: "i-fa6-regular-file-pdf",
      ocr: "i-heroicons-rocket-launch-solid",
      llm: "i-heroicons-rocket-launch-solid",
      trash: "i-heroicons-trash-solid",
      edit: "i-heroicons-pencil-solid",
      //
    },
  },
})
