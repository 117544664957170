<script setup lang="ts">
import type { Link, Avatar, Chip, Badge, Tooltip } from "#ui/types"
import { ULink } from "#components"
import { useWindowSize } from "@vueuse/core"
import { twJoin, twMerge } from "tailwind-merge"

export interface SidebarLink extends Link {
  label: string
  class?: string
  click?: () => any
  //
  labelClass?: string
  icon?: string
  iconClass?: string
  avatar?: Avatar
  avatarClass?: string
  chip?: string | Chip
  chipClass?: string
  badge?: string | number | Badge
  tooltip?: Tooltip
  defaultOpen?: boolean
  // Only applicable to links with children
  draggable?: boolean
  collapsible?: boolean
  children?: SidebarLink[]
}

defineProps<{ links: SidebarLink[] }>()
const collapsed = defineModel<boolean>("collapsed", { required: true })

const { height } = useWindowSize()

const router = useRouter()

const ui = computed(() => {
  const baseUI = /* ui */ {
    base: "group flex flex-row space-x-2 items-center h-12",
    wrapper: "mx-3 my-2 space-y-1",
    padding: "p-4",
    font: "text-sm truncate",
    active: "bg-gray-50 dark:bg-gray-800",
    inactive: "hover:text-gray-600 dark:group-hover:text-gray-500",
    width: "w-full",
    label: "truncate relative",
    rounded: "rounded-md",
    size: "text-sm",
    icon: {
      base: "flex-shrink-0 w-5 h-5",
      active: "",
      inactive: "group-hover:text-gray-600 dark:group-hover:bg-gray-500",
    },
  }
  if (!collapsed.value) {
    baseUI.active += " border-l-4 border-orange-400 dark:border-orange-800"
    baseUI.icon.base += " mr-2"
    baseUI.active += " -mx-1"
  }
  return baseUI
})
</script>

<template>
  <div class="relative w-full max-w-min flex-1 flex-col items-stretch">
    <div class="relative">
      <UButton
        class="absolute -right-3 top-[3.1rem] z-10 shadow-md"
        size="xs"
        color="white"
        variant="solid"
        :ui="{ rounded: 'rounded-full', font: 'font-normal', icon: { size: { xs: 'text-sm' } } }"
        :icon="collapsed ? 'i-ri-arrow-right-s-line' : 'i-ri-arrow-left-s-line'"
        @click="collapsed = !collapsed"
      />
    </div>

    <div
      class="transition-width relative flex h-screen flex-1 shrink-0 flex-col overflow-hidden border-r border-gray-100 duration-200 dark:border-gray-700"
      :class="collapsed ? 'w-[4.5rem]' : 'w-48'"
    >
      <div class="flex min-h-0 grow flex-col gap-y-2 py-2">
        <button
          v-if="height > 600"
          class="flex h-16 items-center border-b px-3"
          @click="router.push('/')"
        >
          <img :src="collapsed ? '/anthracit-logo.png' : '/anthracit.png'" />
        </button>

        <nav :class="ui.wrapper" class="mt-2">
          <div v-for="(link, index) of links" :key="index">
            <component
              :is="link.disabled ? 'div' : ULink"
              v-slot="slotProps"
              v-bind="link"
              :class="[
                ui.base,
                ui.padding,
                ui.width,
                ui.rounded,
                ui.font,
                ui.size,
                link.disabled ? 'cursor-not-allowed opacity-40' : '',
              ]"
              :active-class="ui.active"
              :inactive-class="ui.inactive"
              @click="() => (!link.disabled && link.click ? link.click() : undefined)"
              @keyup.enter="$event.target.blur()"
            >
              <UIcon
                v-if="link.icon"
                :name="link.icon"
                :class="
                  twMerge(
                    twJoin(ui.icon.base, slotProps?.isActive ? ui.icon.active : ui.icon.inactive),
                    link.iconClass,
                  )
                "
              />

              <span v-if="link.label" :class="twMerge(ui.label, (link as any).labelClass)">
                {{ link.label }}
              </span>
            </component>
          </div>
        </nav>

        <div id="teleport-sidebar" class="h-full" />
      </div>
    </div>
  </div>
</template>
