import { defineStore } from "pinia"

type UIStoreStateT = {
  sidebarCollapsed: boolean
  showCommandModal: boolean
}

const defaultState = {
  sidebarCollapsed: false,
  showCommandModal: false,
} satisfies UIStoreStateT

export const uiStoreStorageKey = "ui-store"

export const useUIStore = defineStore("ui", {
  state: (): UIStoreStateT => {
    const previous_state = JSON.parse(localStorage.getItem(uiStoreStorageKey) || "{}")
    return { ...defaultState, ...previous_state }
  },
  actions: {
    resetState() {
      this.$state = defaultState
    },
  },
})
